import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Text } from "@mantine/core";
import { labelSizes, WEIGHT_BOLD } from "./theme/typography";
const labelConfig = {
    sm: {
        size: labelSizes["sm"],
        lts: "1.12px",
    },
    xs: {
        size: labelSizes["xs"],
        lts: "0.96px",
    },
};
export const Label = ({ children, size = "xs", ...rest }) => (_jsx(Text, { ...rest, lts: labelConfig[size].lts, size: labelConfig[size].size, fw: WEIGHT_BOLD, transform: "uppercase", children: children }));
